.double-fullwidth-section{
    background-color: var(--gray-color);    
    .double-fullwidth-section--bg-img{
        min-height: 25.3rem;
    }
    .double-fullwidth-section--content{
        padding: 2.8rem 0rem 2.8rem 4.8rem;
    }
}  

// Small devices 
@media (min-width: 576px) {
    .double-fullwidth-section{
        .double-fullwidth-section--bg-img{
            min-height: 35rem;
        }
    }  
}

// Medium devices 
@media (min-width: 768px) {
    .double-fullwidth-section{
        .double-fullwidth-section--bg-img{
            min-height: 50.3rem;
        }
    }  
}

// Large devices
@media (min-width: 992px) {
}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}