.double-fullwidth-section {
  background-color: var(--gray-color);
}
.double-fullwidth-section .double-fullwidth-section--bg-img {
  min-height: 25.3rem;
}
.double-fullwidth-section .double-fullwidth-section--content {
  padding: 2.8rem 0rem 2.8rem 4.8rem;
}

@media (min-width: 576px) {
  .double-fullwidth-section .double-fullwidth-section--bg-img {
    min-height: 35rem;
  }
}
@media (min-width: 768px) {
  .double-fullwidth-section .double-fullwidth-section--bg-img {
    min-height: 50.3rem;
  }
}